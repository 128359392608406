import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Head from "next/head";
import Link from "next/link";
import Router from "next/router";
import { LocalizationProvider, Localized } from "@fluent/react";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { validate } from "@kikoff/utils/src/string";

import PrimaryButton from "@component/buttons/primary";
import { ErrorPanel } from "@component/errors";
import Form from "@component/form";
import { TextInput } from "@component/inputs";
import Header from "@component/layout/header";
import MfaRequiredBanner from "@component/onboarding/mfa/login_banner";
import { initUser } from "@feature/user";
import { useOverlaysController } from "@src/overlay";
import { sputter } from "@util/analytics";
import { l10nResources } from "@util/l10n";

import styles from "./login.module.scss";

const l10n = l10nResources({
  en: `email-placeholder = Email
password-placeholder = Password
login-welcome-title = Welcome back!
forgot-password = Forgot password
forgot-email = Forgot email
login-no-acct = Don’t have an account?
login-signup-here = Sign up here
login = Log in
email = Email
contact = Contact
back-to-login = Back to login
reset-password = Reset Password
email-address = Email Address
`,
  es: `email-placeholder = Email
password-placeholder = Clave
login-welcome-title = ¡Bienvenido de nuevo!
forgot-password = Has olvidado tu contraseña
forgot-email = Has olvidado tu correo electrónico
login-no-acct = ¿No tienes una cuenta?
login-signup-here = Registrate aquí
login = Iniciar sesión
email = Correo electrónico
contact = Contacto
back-to-login = Atrás para iniciar sesión
reset-password = Restablecer la contraseña
email-address = Dirección de correo electrónico
`,
});

export default function LoginPage() {
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const overlays = useOverlaysController();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    const resetEmailQueryParam = new URLSearchParams(
      window.location.search
    ).get("reset_email");

    if (resetEmailQueryParam !== null) {
      setView(1);
    }
  }, []);

  useEffect(() => {
    // XXX: removing overlays handles an edgecase when refreshing page with
    //   an overlay open, then closing via "X" to logout. An invisible
    //   overlay can remain over the login form.
    overlays.removeAll();

    if (user.mfaRequired) {
      overlays.push("identity_verification/mfa", {
        purpose: "USER_LOGIN",
      });
    }
  }, [user.mfaRequired]);

  // Put here intentionally to reset form whenever view changes
  function ResetPassword() {
    const [message, setMessage] = useState("");

    return (
      <Form
        expand
        dynamic
        title={l10n.getString("reset-password")}
        className={styles["forgot-password-form"]}
        fieldsMinHeight="130px"
        submit={{
          buttonContent: {
            initial: l10n.getString("reset-password"),
          },
          before(sendRequest, { formObj }) {
            sputter("auth: submitted password reset", {
              contact: formObj.type,
            });
            sendRequest();
          },
          request: {
            service: webRPC.Auth,
            method: "resetPassword",
            buildProto: ({ email }) => {
              return {
                contactPoint: email,
              };
            },
            getStatusHandlers: ({ setButton }) => ({
              SUCCESS(data) {
                setButton("success");
                setMessage(
                  {
                    EMAIL:
                      "If the email is valid, you'll receive a password reset link.",
                  }[
                    web.public_.ResetPasswordResponse.ContactPointType[
                      data.contactPointType
                    ]
                  ]
                );
              },
            }),
          },
        }}
      >
        <div className={styles.container}>
          <TextInput
            variant="outlined"
            label={l10n.getString("email-address")}
            className={styles.contact}
            key="email"
            name="email"
            autoFocus
            validate={validate.email}
          />
        </div>
        <span className={styles.back} onClick={() => setView(0)}>
          <span className={styles.icon}></span>{" "}
          {l10n.getString("back-to-login")}
        </span>
        <div className={styles.message}>
          <ErrorPanel
            // @ts-expect-error
            message={message}
            show={!!message}
            type="Success"
            color="#00f300"
            onClose={() => setMessage("")}
          />
        </div>
      </Form>
    );
  }

  return (
    <div className={styles["login-page"]}>
      <LocalizationProvider l10n={l10n}>
        <Head>
          <title>Login | Kikoff</title>
        </Head>
        <Header className={styles.header} fixed />
        <MfaRequiredBanner />
        {view === 0 && (
          <div className={styles["form-container"]}>
            <Form
              button={({ content }) => (
                <PrimaryButton
                  data-cy="login"
                  type="submit"
                  className={styles.submit}
                >
                  {content}
                </PrimaryButton>
              )}
              expand
              title={
                <div className={styles.title}>
                  {l10n.getString("login-welcome-title")}
                </div>
              }
              className={styles["login-form"]}
              submit={{
                buttonContent: {
                  initial: l10n.getString("login"),
                },
                request: {
                  service: webRPC.Auth,
                  method: "login",
                  buildProto(formObj) {
                    return {
                      email: formObj.email,
                      password: formObj.password,
                    };
                  },
                  getStatusHandlers: ({ setButton }) => ({
                    SUCCESS({ mfaRequired }) {
                      sputter("auth: log in", { mfaRequired, form: "login" });

                      dispatch(initUser({ delay: 300 })).then(() => {
                        // if user exits MFA form, we log them out and dont want
                        // to reflect a successful login message on the button.
                        setButton(mfaRequired ? "initial" : "success");
                      });
                    },
                  }),
                },
              }}
            >
              <TextInput
                variant="outlined"
                label={l10n.getString("email-placeholder")}
                id="email"
                name="email"
                autoCapitalize="off"
                autoComplete="email"
                validate={validate.contactPoint}
              />
              <TextInput
                variant="outlined"
                label={l10n.getString("password-placeholder")}
                id="password"
                type="password"
                name="password"
                autoComplete="current-password"
                actions={["show/hide"]}
                validate={(str) => !!str}
              />
              <span
                className={styles["forgot"]}
                data-cy="forgot-password-button"
                onClick={() => {
                  sputter("auth: clicked forgot password");
                  setView(1);
                }}
              >
                {l10n.getString("forgot-password")}{" "}
                <span className={styles.icon}></span>
              </span>
              <span
                className={styles["forgot"]}
                onClick={() => {
                  sputter("auth: clicked forgot email");
                  Router.replace("forgot-email/form");
                }}
              >
                {l10n.getString("forgot-email")}{" "}
                <span className={styles.icon}></span>
              </span>
            </Form>
            <div className={styles["signup-link"]}>
              <Localized id="login-no-acct">Don’t have an account?</Localized>{" "}
              <Link href="/onboarding">
                <a>
                  <Localized id="login-signup-here">Sign up here</Localized>
                </a>
              </Link>
            </div>
          </div>
        )}
        {view === 1 && <ResetPassword />}
      </LocalizationProvider>
    </div>
  );
}
