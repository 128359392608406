import { combineClasses } from "@kikoff/utils/src/string";

import Card from "../cards/Card";

import styles from "./Alert.module.scss";

const iconMap = {
  info: "",
  warning: "",
  error: "",
};

declare namespace Alert {
  type Props = React.ComponentPropsWithoutRef<"div"> & {
    type: keyof typeof iconMap;
    icon?: string;
    title?: React.ReactNode;
    description?: React.ReactNode;
    dugout?: boolean;
  };
}

export default Alert;
function Alert({
  type,
  className,
  icon = iconMap[type],
  title,
  description,
  children,
  dugout,
  ...props
}: Alert.Props) {
  return (
    <Card
      outline={type === "info" ? !dugout : `var(--${type})`}
      className={combineClasses(
        styles.alert,
        styles[type],
        dugout && ["bg:dugout", "color-base:dugout"],
        className
      )}
      {...props}
    >
      <div className={`color:${type === "info" ? "strong" : type}`}>{icon}</div>
      <div className={styles.content}>
        {title && (
          <header className="text:regular+ color:strong">{title}</header>
        )}
        {description && (
          <div className="text:small color:moderate">{description}</div>
        )}
        {children}
      </div>
    </Card>
  );
}
