import React from "react";

import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./primary.module.scss";

// FIXME(kcirtaptrick)
// type PrimaryButtonProps<
//   T extends keyof JSX.IntrinsicElements | React.FC
// > = (T extends keyof JSX.IntrinsicElements
//   ? JSX.IntrinsicElements[T]
//   : T extends React.FC
//   ? ReactProps<T>
//   : never) & {
//   as?: T;
//   className?: string;
//   style?: React.CSSProperties;
//   variant?: "default" | "underline" | "secondary";
//   color?: React.CSSProperties["color"];
//   state?: "active" | "disabled";
//   children?: React.ReactNode;
// };

type PrimaryButtonProps<T> = {
  as?: React.FC<any> | keyof JSX.IntrinsicElements;
  className?: string;
  variant?: "default" | "underline" | "secondary";
  color?: React.CSSProperties["color"];
  state?: "active" | "disabled";
  children?: React.ReactNode;
  [key: string]: any;
};

/**
 * @deprecated Use KButton instead
 */
export default function PrimaryButton<
  T extends React.FC | keyof JSX.IntrinsicElements = "button"
>({
  className,
  variant = "default",
  color = "var(--new-primary-color)",
  children,
  state = "active",
  style,
  as: Component = "button",
  ...props
}: PrimaryButtonProps<T>) {
  return (
    <Component
      className={combineClasses(
        styles["primary-button"],
        styles[`variant_${variant}`],
        styles[`state_${state}`],
        className
      )}
      style={{
        "--color": color,
        ...style,
      }}
      type="button"
      {...props}
    >
      {children}
    </Component>
  );
}
